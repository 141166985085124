import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import core from "./core/index";

Vue.use(Vuex);
const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});
export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    app,
    appConfig,
    verticalMenu,
    core,
    "app-ecommerce": ecommerceStoreModule,
  },
  strict: process.env.DEV,
  state: {
    userData: {
      cId: 0,
      userID: 0,
      username: 0,
      rememberMe: false,
      isLoggedIn: false,
      userType: 0,
      roles: null,
      token: "",
      db: "",
      studentStatus: "",
      password: "",
      encPassword: "",
      tID: 0,
    },
    domain: "https://api.myskool.app/api/",
    report: "reports",
    history: false,
    monthNo: 1,
    salYear: new Date().getFullYear(),
    salPaymentDate: new Date().toISOString().slice(0, 10),
    accountType: 0,
    date: "",
    branches: [],
    branchName: "",
    currentBranch: {},
    logo: "",
    highestCollection: 0,
    menu: [],
    currentMenu: {},
    rights: {},
    permissions: "",
    inqNotification: true,
    marksSelection: [],
    resultSetting: {
      number: 3,
      rangeDate: "",
      absentMarks: 0,
    },
    sortBy: "",
    attSort: "name",
    studentSetting: {
      sameDate: false,
      sameClass: false,
      sameSec: false,
    },
    sameTimings: true,
    clientID: "",
    addMsg: false,
    quizData: {
      time: 10000,
      started: false,
      submitted: false,
      loading: false,
      questions: [],
      currentQues: {},
      currentIndex: 0,
      userSelected: [],
      score: 0,
      attemptCount: 0,
      skipIndexes: [],
      unattempted: [],
    },
    quizAutoData: {
      time: 3000,
      started: false,
      submitted: false,
      loading: false,
      questions: [],
      currentQues: {},
      currentIndex: 0,
      userSelected: [],
      score: 0,
      attemptCount: 0,
      skipIndexes: [],
      unattempted: [],
    },
    quizInstance: 0,
    stdSearch: "",
    decimal: 1,
    inquiryData: [],
    dbList: [],
    selectedSession: 0,
    creatorStates: [true, true],
    // schoolTiming: "mon to fri",
    syncList: [],
    staffSyncList: [],
    journalStatus: "",
    vType: "",
    loggedUser: {},
    stdLoggedIn: false,
    stdFilter: [],
    staffFilter: [],
  },
  getters: {
    userData: (state) => state.userData,
  },
  mutations: {
    campusId(state, payload) {
      state.userData.cId = payload;
    },
    setDB(state, payload) {
      state.userData.db = payload;
    },
    setCreatorStates(state, payload) {
      state.creatorStates = payload;
    },
    setDBList(state, payload) {
      state.dbList = payload;
    },
    stdStatus(state, payload) {
      state.userData.studentStatus = payload;
    },
    setPassword(state, payload) {
      state.userData.password = payload;
    },
    setEncPassword(state, payload) {
      state.userData.encPassword = payload;
    },
    setDomain(state, payload) {
      state.domain = payload;
    },
    setReport(state, payload) {
      state.report = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setAccountType(state, payload) {
      state.accountType = payload;
    },
    setDate(state, payload) {
      state.date = payload;
    },
    setMonthNo(state, payload) {
      state.monthNo = payload;
    },
    setSalYear(state, payload) {
      state.salYear = payload;
    },
    setSalPaymentDate(state, payload) {
      state.salPaymentDate = payload;
    },
    setBranches(state, payload) {
      state.branches = payload;
    },
    setBranchName(state, payload) {
      state.branchName = payload;
    },
    setCurrentBranch(state, payload) {
      state.currentBranch = payload;
    },
    setLogo(state, payload) {
      state.logo = payload;
    },
    setMenu(state, payload) {
      state.menu = payload;
    },
    setCurrentMenu(state, payload) {
      state.currentMenu = payload;
    },
    setRights(state, payload) {
      state.rights = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setInquiry(state, payload) {
      state.inqNotification = payload;
    },
    setSelection(state, payload) {
      state.marksSelection = payload;
    },
    setResult(state, payload) {
      state.resultSetting = payload;
    },
    setSort(state, payload) {
      state.sortBy = payload;
    },
    setAttSort(state, payload) {
      state.attSort = payload;
    },
    setStdSetting(state, payload) {
      state.studentSetting = payload;
    },
    setSameTimings(state, payload) {
      state.sameTimings = payload;
    },
    setClientID(state, payload) {
      state.clientID = payload;
    },
    setAddMsg(state, payload) {
      state.addMsg = payload;
    },
    setQuiz(state, payload) {
      state.quizData = payload;
    },
    setQuizAuto(state, payload) {
      state.quizAutoData = payload;
    },
    setInstance(state, payload) {
      state.quizInstance = payload;
    },
    setSearch(state, payload) {
      state.stdSearch = payload;
    },
    setDecimal(state, payload) {
      state.decimal = payload;
    },
    setInquiryData(state, payload) {
      state.inquiryData = payload;
    },
    setSession(state, payload) {
      state.selectedSession = payload;
    },
    setSyncList(state, payload) {
      state.syncList = payload;
    },
    setStaffSyncList(state, payload) {
      state.staffSyncList = payload;
    },
    setJournalStatus(state, payload) {
      state.journalStatus = payload;
    },
    setVType(state, payload) {
      state.vType = payload;
    },
    setLoggedUser(state, payload) {
      state.loggedUser = payload;
    },
    setStdLoggedIn(state, payload) {
      state.stdLoggedIn = payload;
    },
    setStdFilter(state, payload) {
      state.stdFilter = payload;
    },
    setStaffFilter(state, payload) {
      state.staffFilter = payload;
    },
  },
});
